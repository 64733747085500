export const initialState = {
  categoryId: "",
  categoryName: "",
  productListByCategory: [],
  productListByFilternameCategory: [],
  productListByFilter: [],
  productDetail: {},
  searchList: [],
  "new-arrivals-list": [],
  "best-sellers-list": [],
  "featured-list": [],
  "weekly-list": [],
  "monthly-list": [],
  "annual-list": [],
  ratingDetails: {},
  categoryListByParent: [],
  productOriginalPrice: null,
  productsWithOneTimePayment: []
};

export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CATEGORY_CHANGE":
      return {
        categoryId: action.payload.categoryId,
        categoryName: action.payload.categoryName,
        productListByCategory: [],
      };
    case "PRODUCT_LIST_BY_FILTER":
      return {
        ...state,
        productListByFilter: [...action.payload.data.data],
      };
    case "CATEGORY_LIST_BY_PARENT":
      return {
        ...state,
        categoryListByParent: [...action.payload.data.data],
      };
    case "PRODUCT_LIST_BY_CATEGORY":
      return {
        ...state,
        productListByCategory: [...action.payload.data.data],
      };
    case "PRODUCT_LIST_BY_FILTERNAME_AND_CATEGORY":
      return {
        ...state,
        productListByFilternameCategory: [...action.payload.data.data],
      };
    case "PRODUCT_DETAIL":
      return {
        ...state,
        productDetail: action.payload.data.data,
        productOriginalPrice: action.payload.data.data.price,
      };

    case "RESET_PRODUCT_DETAIL":
      return {
        ...state,
        productDetail: {}
      }
    case "PRODUCT_PRICE":
      return {
        ...state,
        productDetail: Object.assign(state.productDetail, {
          price: action.payload?.data?.data?.data?.price
            ? action.payload.data.data.data.price
            : state.productDetail.price,
        }),
      };
    case "SEARCH_LIST":
      return {
        ...state,
        searchList: action.payload.data.data,
      };
    case "new-arrivals-list":
      return {
        ...state,
        "new-arrivals-list": action.payload.data.data,
      };
    case "best-sellers-list":
      return {
        ...state,
        "best-sellers-list": action.payload.data.data,
      };
    case "featured-list":
      return {
        ...state,
        "featured-list": action.payload.data.data,
      };
    case "weekly-list":
      return {
        ...state,
        "weekly-list": action.payload.data.data,
      };
    case "monthly-list":
      return {
        ...state,
        "monthly-list": action.payload.data.data,
      };
    case "annual-list":
      return {
        ...state,
        "annual-list": action.payload.data.data,
      };
    case "GET_PRODUCT_RATING_FOR_USER":
      return {
        ...state,
        ratingDetails: action.payload.data,
      };

    default:
      return state;
  }
};
