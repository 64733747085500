import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import './index.css';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import rootReducer from "./Redux/Reducers";
import ScrollToTop from "./Utils/scrollToTop";
import history from  './Containers/History/History'
import App  from "./App";

const middleware = [thunk];
let store = createStore(rootReducer, applyMiddleware(...middleware));
if (process.env.NODE_ENV !== 'production') {
  const logger = require('redux-logger');
  middleware.push(logger);
  // const { composeWithDevTools } = require('redux-devtools-extension');
  // const composeEnacher = composeWithDevTools({ trace: true, traceLimit: 25 })
  // store = createStore(rootReducer, composeEnacher(applyMiddleware(...middleware)));
}
ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
