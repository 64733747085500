import React, { Component, Suspense, lazy } from 'react';
import './App.css';
import './Styles/all.scss';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './Styles/style.scss';
import { Switch, withRouter } from "react-router-dom";
import { Route } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUserDetail, setUserInfo } from './Redux/Actions/userAction';
import './Styles/media.scss';
const PrivateRoutes  = lazy(() => import("./Routing/privateRoutes"));
const PermissionError  = lazy(() => import("./Components/PermissionError/permissionError"));

class App extends Component {

  userData = localStorage.getItem('userData');

  constructor(props) {
    super(props);
    if (this.userData) {
      this.fetchUserDetail();
    }
    this.childDiv = React.createRef();

    window.addEventListener('scroll', this.onWindowScroll)
  }

  componentDidMount() {
    this.handleScroll();
  }

  onWindowScroll = () => {
    let mybutton = document.getElementById("myBtn");
    if(mybutton){
      if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
        mybutton.style.display = "block";
      } else {
        mybutton.style.display = "none";
      }
    }

  }

  scrollTopFunction = () => {
    window.scrollTo(0, 0);
  }

  handleScroll = () => {

    const { index, selected } = this.props
    if (index === selected) {
      setTimeout(() => {
        this.childDiv.current.scrollIntoView(true);
      }, 100)
    }
  }


  async fetchUserDetail() {
    const userDetail = await this.props.getUserDetail(JSON.parse(this.userData));
    if (userDetail?.data?.data) {
      this.props.setUserInfo(userDetail.data.data);
    }
  }


  render() {
    return (
      <div ref={this.childDiv}>
        {/* <Spinner /> */}
        <Suspense fallback={<div></div>}>
        <Switch>
            <Route exact path="/error" component={PermissionError} />
            {/* <Route exact path="/signup" component={SignUp} /> */}
            <Route path="/" component={PrivateRoutes} />
          </Switch>
        </Suspense>
        <button onClick={this.scrollTopFunction} id="myBtn" title="Go to top"><i className="fas fa-arrow-up"></i></button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getUserDetail, setUserInfo }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
