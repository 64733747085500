export const initialState = {
  userInfo: null,
  userData: {},
  isUserAdded: "",
  isDeleted: "",
  isUserUpdated: "",
  isUpdated: "",
  userDetails: [],
  unreadMessageCount: 0,
  unreadVendorMessageCount: 0,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SETUSERINFO":
      return {
        ...state,
        userInfo: action.payload,
      };
    case "GETUSERBYCOMPANY":
      return {
        ...state,
        userData: action.payload.data.data,
      };
    case "ADDUSERS":
      return {
        ...state,
        isUserAdded: action.payload.data.message,
      };
    case "DELETEUSERS":
      return {
        ...state,
        isUserDeleted: action.payload.data.message,
      };
    case "UPDATEUSERS":
      return {
        ...state,
        isUserUpdated: action.payload.data.message,
      };
    case "UNREAD_MESSAGE_COUNT":
      return {
        ...state,
        unreadMessageCount: action.payload.data.data.count,
      };
      case "UNREAD_VENDOR_MESSAGE_COUNT":
        return{
          ...state,
          unreadVendorMessageCount: action.payload.data.data.count,
        }
    case "GETUSERDETAILS":
      return {
        ...state,
        userDetails: action.payload.data.data,
      };
    default:
      return state;
  }
};
