export const initialState = {
  loading: false,
  errorData: {},
  errorMessage: "",
  isRegistered: false,
  calendarLoading: false
};

export const commonReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case "LOADING_STARTED":
      return {
        ...state,
        loading: true,
      };
    case "CALENDAR_LOADING_STARTED":
      return {
        ...state,
        calendarLoading: true,
      };
    case "CALENDAR_LOADING_COMPLETED":
      return {
        ...state,
        calendarLoading: false,
      };
    case "LOADING_COMPLETED":
      return {
        ...state,
        loading: false,
      };
    case "LOADING_FAILURE":
      return {
        ...state,
        loading: false,
      };
    case "ERROR":
      return {
        ...state,
        errorData: action.payload?.error?.errorData,
        errorMessage: action.payload?.errorMessage,
      };
    default:
      return state;
  }
};
